import { useTranslation } from 'react-i18next'
import { closeLogOutModal } from '../../actions/modals'
import BottomSheet from '../common/BottomSheet'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { logoutUser } from '../../actions/user'
import { useRouter } from 'next/router'

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    display: 'flex',
    gap: 12,
    marginTop: 12,
  },
  button: {
    flexGrow: 1,
  },
}))

const LogOutDialog = ({}) => {
  const open = useSelector((state) => state.modals.open.logOut)
  const dispatch = useDispatch()
  const onClose = () => dispatch(closeLogOutModal())
  const { t } = useTranslation('common')
  const { classes } = useStyles()
  const router = useRouter()

  return (
    <BottomSheet
      title={'Oops'}
      disableBackdropClick={false}
      open={open ?? false}
      onClose={onClose}
    >
      <Typography style={{ lineHeight: '24px' }} variant='body1'>
        {t('wrongLinkSignOut')}
      </Typography>
      <div className={classes.buttonContainer}>
        <FlatButton
          className={classes.button}
          onClick={async () => {
            await dispatch(logoutUser(router.asPath))
            dispatch(closeLogOutModal())
          }}
        >
          {t('profileSettings.signOut')}
        </FlatButton>
      </div>
    </BottomSheet>
  )
}

export default LogOutDialog
